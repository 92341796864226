<template>
    <CompModal class="comp-live" ref="live_modal" title="议事直播" height="auto" @on-close="onClose">
        <div class="video-box" :key="video_key">
            <video ref="video" class="video video-js vjs-default-skin" controls></video>
        </div>
    </CompModal>
</template>

<script>
import "video.js/dist/video-js.css"
import CompModal from "../../residentdatabase/components/CompModal.vue"

import videojs from "video.js"
import Request from "../../jointly/utils/request"

export default {
    components: { CompModal },

    data() {
        return {
            video_key: 0,
            video_src: null,
        }
    },

    methods: {
        room(id) {
            if (this.room_id === id && this.controller) {
                // 显示弹窗
                this.$refs.live_modal.display()
                // 播放
                return this.controller.play()
            }

            this.room_id = id
            this.video_key += 1

            Request.get("/gateway/api/sycomponent/pc/broadcast/getByRoomId", {
                roomId: id,
            }).then(res => {
                if (res?.liveStatus === 1) {
                    return this.$Message.warning("当前正在直播中，请使用随约小程序进行观看。")
                }

                if (!res?.mediaUrl) {
                    return this.$Message.warning("当前直播暂无回放视频源。")
                }

                this.display(res.mediaUrl)
            })
        },

        display(src) {
            this.controller = videojs(
                this.$refs.video,
                {
                    bigPlayButton: false,
                    textTrackDisplay: false,
                    posterImage: true,
                    errorDisplay: false,
                    controlBar: true,
                    width: 700,
                    height: 500,
                    sources: [
                        {
                            type: "application/x-mpegURL",
                            src, //视频播放地址
                        },
                    ],
                },
                function() {
                    this.play()
                }
            )

            this.$refs.live_modal.display()
        },

        onClose() {
            this.controller?.pause()
        },
    },
}
</script>
